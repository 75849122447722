/**
 * Basic Usage:
 *  useWebviewEmit('some_event');
 *
 * Error Usage: *
 *  useWebviewEmit(
 *     'webview_error',
 *      {
 *          error: {
 *              title: 'Title',
 *              message: 'Message',
 *              logMessage: 'Log Message'
 *          }
 *      },
 *      'error'
 *  );
 *
 */
let deviceType = "ios";

export function useSetDeviceType(type = "ios") {
    deviceType = type;
}

// by convention, composable function names start with "use"
export function useWebviewEmit(event, data = {}, status = "success") {

    // String event
    let eventData = {
        event: event,
        data: data,
        status: status,
    };

    // Non-JSON event
    if (!isJsonEvent()) {
        // Android can only receive primitive data types
        eventData = JSON.stringify(eventData);
    }

    try {
        if (isAndroid()) {
            logEmit('android', event, eventData);
            window.Android.postMessage(eventData);
            return self;
        }
        if (isIos()) {
            logEmit('ios', event, eventData);
            window.webkit.messageHandlers.callbackHandler.postMessage(
                eventData
            );
            return self;
        }
        console.warn("Device type was not detected");
    } catch (e) {
        console.warn("Communication with the host app failed");
    }
}

function logEmit(deviceType, event, eventData)
{
    // Can leave console.log() in, app users can't see the output
    console.log([event, eventData]);

    /**
     * Create hidden element with event information for Dusk
     * tests to verify events
     */
    let container = document.createElement('div');
    container.setAttribute('style', 'display:none');
    container.id = 'device-emit-log';
    container.setAttribute('data-event', event);
    container.setAttribute('data-device-type', deviceType);
    container.setAttribute('data-event-data-raw', eventData);
    container.setAttribute('data-event-data', JSON.stringify(eventData));

    // enable to see output on screen
    /*
    container.innerHTML = `
        <div class="device-type">${deviceType}</div>
        <div class="event">${event}</div>
        <div class="event-data-raw">${eventData}</div>
        <div class="event-data">${JSON.stringify(eventData)}</div>
    `;
    */
    if (document.querySelector('#device-emit-log')) {
        document.querySelector('#device-emit-log').replaceWith(container);
    } else {
        document.querySelector('body').append(container);
    }
}

/**
 * @returns {boolean}
 */
function isAndroid() {
    return deviceType === "android";
}

/**
 * @returns {boolean}
 */
function isIos() {
    return deviceType === "ios";
}

/**
 *
 * @returns {*|define.amd.jQuery}
 */
function isJsonEvent() {
    return isIos();
}
